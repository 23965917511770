@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
html{
  scroll-behavior: smooth;
}
@media screen and (min-width: 900px) 
{
.carousel-list::-webkit-scrollbar {
    width: 1px;
  }
  
  .carousel-list::-webkit-scrollbar-track {
    background-color: #ffffff00;
  }
  
  .carousel-list::-webkit-scrollbar-thumb {
    width: 1px;
    background-color: #88888800;
    border-radius: 4px;
  }
  
  .carousel-list::-webkit-scrollbar-thumb:hover {
    background-color: #bdacac00;
  }
}

@media only screen and (min-width: 1200px) { 

.video{
  margin-top:-240px;
}  

}
.arrow-bullet {
  list-style-type: none;
  padding-left: 20px;
}

.arrow-bullet li {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
}

.arrow-bullet li:before {
  content: "➤";
  margin-right: 10px;
  color:#462c73
}